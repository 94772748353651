<template>
  <main>
    <LoaderAnimation v-if="loading" />
    <div class="reader-wrapper">
      <vue-pdf-app
        v-if="documentPath && isMobile"
        v-show="!loading"
        :pdf="documentPath"
        :config="config"
        @pages-rendered="loading = false"
      ></vue-pdf-app>
      <iframe
        v-else
        v-show="!loading"
        :src="documentPath"
        frameborder="0"
        @load="loading = false"
      ></iframe>
      <div v-if="play || fetchedPlay" class="button-wrapper">
        <Exclusive :play="play || fetchedPlay" />
      </div>
    </div>
  </main>
</template>

<script>
import { scriptCollections, firebase, auth } from "@/firebase-config";
import { LoaderAnimation, Exclusive } from "@/components";
import { detectMobile } from "@/utils/mobile-detection";

import VuePdfApp from "vue-pdf-app";
import "vue-pdf-app/dist/icons/main.css";
export default {
  name: "Reader",
  props: {
    play: Object,
    playId: String,
  },
  components: {
    VuePdfApp,
    LoaderAnimation,
    Exclusive,
  },
  data() {
    return {
      config: {
        sidebar: {
          viewThumbnail: true,
          viewOutline: true,
          viewAttachments: false,
        },
        toolbar: {
          toolbarViewerLeft: { findbar: false },
          toolbarViewerRight: {
            presentationMode: true,
            openFile: false,
            print: false,
            download: false,
            viewBookmark: true,
          },
        },
        secondaryToolbar: false,
      },
      documentPath: null,
      loading: true,
      fetchedPlay: null,
    };
  },
  methods: {
    async getPlay() {
      try {
        const doc = await scriptCollections.doc(this.playId).get();
        if (doc.exists && doc.data().owner === null) {
          this.fetchedPlay = {
            id: doc.id,
            ...doc.data(),
          };
          this.documentPath = doc.data().pdf;
        } else
          throw new Error("There is no document with this id in the database");
      } catch (err) {
        throw new Error("Something went wrong");
      } finally {
        this.loading = false;
      }
    },
    async incrementViewCount() {
      try {
        const script = scriptCollections.doc(this.playId);
        script.update({
          views: firebase.firestore.FieldValue.increment(1),
          viewedBy: firebase.firestore.FieldValue.arrayUnion(
            auth.currentUser.uid
          ),
        });
      } catch (err) {
        throw new Error(err);
      }
    },
  },
  computed: {
    isMobile() {
      return detectMobile();
    },
  },
  beforeMount() {
    if (this.play) this.documentPath = this.play.pdf;
    else this.getPlay();
    this.incrementViewCount();
  },
  async beforeRouteEnter(to, from, next) {
    next((vm) => {
      setTimeout(() => {
        if (vm.$store.getters.isApproved(vm.playId)) {
          next();
        } else
          next({
            name: "SignIn",
            query: {
              name: to.name,
              params: JSON.stringify(to.params),
            },
          });
      }, 100);
    });
  },
};
</script>

<style lang="scss" scoped>
.pdf-app.dark {
  --pdf-app-background-color: #141414;
  --pdf-sidebar-content-color: #141414;
  --pdf-toolbar-sidebar-color: #141414;
  --pdf-toolbar-color: #141414;
  --pdf-loading-bar-color: #606c88;
  --pdf-loading-bar-secondary-color: #11ece5;
  --pdf-find-results-count-color: #d9d9d9;
  --pdf-find-results-count-font-color: #525252;
  --pdf-find-message-font-color: #a6b7d0;
  --pdf-not-found-color: #f66;
  --pdf-split-toolbar-button-separator-color: #fff;
  --pdf-toolbar-font-color: #d9d9d9;
  --pdf-button-hover-font-color: #11ece5;
  --pdf-button-toggled-color: #606c88;
  --pdf-horizontal-toolbar-separator-color: #fff;
  --pdf-input-color: #333;
  --pdf-input-font-color: #d9d9d9;
  --pdf-find-input-placeholder-font-color: #11ece5;
  --pdf-thumbnail-selection-ring-color: hsla(0, 0%, 100%, 0.15);
  --pdf-thumbnail-selection-ring-selected-color: hsla(0, 0%, 100%, 0.3);
  --pdf-error-wrapper-color: #e50914;
  --pdf-error-more-info-color: #d9d9d9;
  --pdf-error-more-info-font-color: #000;
  --pdf-overlay-container-color: rgba(0, 0, 0, 0.2);
  --pdf-overlay-container-dialog-color: #24364e;
  --pdf-overlay-container-dialog-font-color: #d9d9d9;
  --pdf-overlay-container-dialog-separator-color: #fff;
  --pdf-dialog-button-font-color: #d9d9d9;
  --pdf-dialog-button-color: #606c88;
}

#vuePdfApp {
  height: calc(100vh - 187px) !important;

  @media (min-width: 1024px) {
    height: calc(100vh - 70px) !important;
  }
}

/deep/ #outerContainer {
  #toolbarViewer {
    padding: 0 4vw;
  }

  #thumbnailView {
    scrollbar-width: none;
    &::-webkit-scrollbar {
      width: 0px;
      background: transparent;
      display: none;
    }
  }

  #errorWrapper {
    padding: 10px 4vw;
    font-size: 1.2rem;

    #errorMessage {
      margin-right: 10px;
    }

    button {
      border-radius: 12px;
      padding: 5px 10px;
      font-size: 1.2rem;
    }
  }
}

iframe {
  width: 100%;
  height: 100vh;
}

/deep/ .loading {
  height: calc(100vh - 70px);
}

.button-wrapper {
  position: fixed;
  bottom: 10px;
  right: 50%;
  transform: translateX(-50%);
  min-height: 50px;
  z-index: 10000000000000000;

  @media (min-width: 764px) {
    right: 100px;
    bottom: 30px;
    transform: translateX(0);
  }
}
</style>
